import { getHintUtils } from "@epic-web/client-hints";
import { clientHint as colorSchemeHint, subscribeToSchemeChange } from "@epic-web/client-hints/color-scheme";
import { clientHint as timeZoneHint } from "@epic-web/client-hints/time-zone";
import { useRevalidator } from "react-router";
import * as React from "react";
import { useRequestInfo } from "./request-info";
import { match } from "ts-pattern";

type Breakpoint = "2xl" | "xl" | "lg" | "md" | "sm" | "xs";

const mediaBreakpoints: { [key in Breakpoint]: number } = {
  "2xl": 1536,
  xl: 1280,
  lg: 1024,
  md: 768,
  sm: 640,
  xs: 0,
};

const mediaBreakpointHint = {
  cookieName: "CH-media-min-width",
  getValueCode:
    Object.keys(mediaBreakpoints)
      .map((breakpoint) => {
        // @ts-ignore
        return `window.matchMedia('(min-width: ${mediaBreakpoints[breakpoint]}px').matches ? '${breakpoint}' : `;
      })
      .reduce((prev, curr) => {
        return `${prev}${curr}`;
      }, "") + "'xs'",
  fallback: "xs",
  transform: (value: string) =>
    match(value)
      .with("2xl", (val) => val)
      .with("xl", (val) => val)
      .with("lg", (val) => val)
      .with("md", (val) => val)
      .with("sm", (val) => val)
      .otherwise(() => "xs") as Breakpoint,
};

const hintsUtils = getHintUtils({
  theme: colorSchemeHint,
  timeZone: timeZoneHint,
  mediaBreakpoint: mediaBreakpointHint,
  // add other hints here
});

export const { getHints } = hintsUtils;

/**
 * @returns an object with the client hints and their values
 */
export function useHints() {
  const requestInfo = useRequestInfo();
  return requestInfo.hints;
}

/**
 * @returns inline script element that checks for client hints and sets cookies
 * if they are not set then reloads the page if any cookie was set to an
 * inaccurate value.
 */
export function ClientHintCheck({ nonce }: { nonce: string }) {
  const { revalidate } = useRevalidator();
  React.useEffect(() => {
    subscribeToSchemeChange(() => revalidate());
    subscribeToWindowResize(() => revalidate());
  }, [revalidate]);

  return (
    <script
      nonce={nonce}
      dangerouslySetInnerHTML={{
        __html: hintsUtils.getClientHintCheckScript(),
      }}
    />
  );
}

function subscribeToWindowResize(subscriber: (value: Breakpoint) => void) {
  function getBreakpoint(): Breakpoint {
    const width = window.innerWidth;
    for (const breakpoint of Object.keys(mediaBreakpoints)) {
      // @ts-ignore
      if (width > mediaBreakpoints[breakpoint]) {
        return breakpoint as Breakpoint;
      }
    }
    return "xs";
  }

  let currentBreakpoint = getBreakpoint();
  function handleResize() {
    let value = getBreakpoint();
    if (currentBreakpoint !== value) {
      currentBreakpoint = value;
      document.cookie = `${mediaBreakpointHint.cookieName}=${value}; Max-Age=31536000; Path=/`;
      subscriber(value);
    }
  }
  window.addEventListener("resize", handleResize);
  return () => window.removeEventListener("resize", handleResize);
}
